body .side {
  padding: 0px 5px; }

.side-wrap .side-wrap-top {
  padding: 10px;
  padding-bottom: 25px;
  margin-bottom: 25px;
  width: 100%;
  padding-left: 5px;
  padding-right: 0px; }

.side-wrap .side-wrap-top img {
  max-width: 100%; }

.side-wrap .side-menu ul#menu-sidmeny li:first-of-type {
  padding-top: 0px; }

.side-wrap .side-menu ul#menu-sidmeny li a {
  font-size: 14px; }

.side-wrap .side-menu ul#menu-sidmeny li ul.sub-menu {
  padding-left: 15px; }

.side-wrap .side-menu ul#menu-sidmeny li ul.sub-menu li {
  padding: 5px 0px; }

.side-wrap .side-menu ul#menu-sidmeny li ul.sub-menu li:first-of-type {
  padding-top: 10px; }

.side-wrap .side-menu ul#menu-sidmeny li ul.sub-menu li a {
  font-size: 12px; }

body .side {
  position: fixed; }

body .landing_section .project-item .project-text {
  color: white;
  text-shadow: 0px 0px 3px #000; }

body .section ul {
  list-style: none;
  padding-left: 0px;
  font-weight: 300; }

body .section ul h1, body .section ul h2, body .section ul h3, body .section ul h4, body .section ul h5 {
  font-weight: initial; }

body .section p {
  font-size: 1.2em; }

body .section h1 {
  font-size: 3.5em;
  letter-spacing: 2px; }

body #fullpage .section h1 {
  margin-top: 90px; }

body .showcase h1 {
  margin-top: 30px; }

body .showcase .showcase-item {
  float: none;
  height: 60vw;
  width: 60vw;
  background-size: cover;
  background-position: center center;
  margin: 30px 0px;
  /* background-color: white; */
  position: relative; }

body .showcase .showcase-item .showcase-desc {
  text-align: center;
  position: absolute;
  bottom: 0px;
  padding: 10px;
  left: 0px;
  right: 0px;
  background-color: white; }

body .showcase .showcase-item .showcase-desc .project-text {
  font-style: italic; }

.small-col {
  width: 15%;
  max-width: 230px; }

@media (min-width: 1300px) {
  body .fp-section {
    max-width: 1200px; } }

@media (min-width: 1400px) {
  body .fp-section {
    max-width: 1300px; } }

@media (min-width: 1500px) {
  body .fp-section {
    max-width: 1400px; } }

#fullpage {
  overflow: hidden; }

#fullpage img {
  max-width: 100%;
  height: auto; }

#fullpage .section h1 {
  margin-top: 90px; }

h3.project-text {
  color: black; }

div#fullpage.showcase .page-wrap.section {
  padding-bottom: 10vh; }

.landing_section {
  min-height: 100vh; }

div#referenser .section-wrap {
  margin-top: 90px; }

@media (max-width: 600px) {
  .side {
    height: auto;
    transition: initial;
    position: fixed;
    width: 100%;
    max-width: initial;
    background-color: white !important; } }

@media (max-width: 1300px) {
  body .side {
    padding: 0px 5px;
    min-width: 240px; }
  body .side .side-wrap .side-wrap-top {
    padding: 10px 5px;
    padding-bottom: 25px; }
  body .side .side-menu ul ul {
    padding-left: 15px; }
  body .side .side-menu ul ul li {
    padding: 5px 0px; }
  body .side .side-menu ul ul li a {
    font-size: 12px; } }

@media (max-width: 1100px) {
  body nav ul {
    padding-left: 20px; }
  body nav ul ul.sub-menu {
    padding-left: 15px; }
  body nav ul ul.sub-menu a {
    font-size: 12px; }
  body .project-item {
    width: calc(100%/4); } }

@media (max-width: 992px) {
  body .side-wrap .side-wrap-top img {
    max-width: 190px; }
  body .side {
    height: 120px;
    transition: initial;
    position: fixed;
    width: 100%;
    max-width: initial;
    background-color: white !important;
    width: 100%;
    position: fixed; }
  body .side .side-wrap .side-wrap-top {
    border: none;
    padding: 5px; }
  body .side .side-menu ul {
    display: none;
    text-align: center;
    top: 86px;
    background-color: white;
    position: absolute;
    left: 0px;
    right: 0px;
    padding-left: 0px;
    transition: 1s all ease;
    margin-top: 30px; }
  body .side .menu-toggle {
    display: block;
    top: 20px; }
  body #fullpage {
    margin-top: 120px; }
  body #fullpage .section-wrap {
    padding: 19px; } }

@media (max-width: 900px) {
  body .section-wrap {
    width: 100%;
    margin-left: 0px; }
  body .section-wrap h1 {
    display: block; }
  body .project-item {
    width: calc(100%/3); } }

@media (max-width: 768px) {
  body .fp-section {
    padding: 19px; }
  body .showcase .showcase-item {
    height: 90vw;
    width: 90vw;
    margin: auto; } }

@media (max-width: 550px) {
  body #fullpage .section h1 {
    font-size: 9vw; }
  body .side {
    height: 80px; }
  body .side .side-menu ul {
    top: 80px;
    margin-top: 0px; }
  body .side .side-wrap .side-wrap-top {
    padding: 5px !important; }
  body .side .logo img {
    width: auto;
    max-height: 60px;
    height: auto; }
  body #fullpage {
    margin-top: 40px; }
  body .project-item {
    width: calc(100%/2); } }
